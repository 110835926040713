import analytic from "./analytic";

/**
 *
 * @param int listingId
 * @param {from:"home|user-based-recommendation|item-based-recommendation"} config
 */
export const TRACK_LISTING_VIEW = (
  listingId,
  config = {
    from: "home",
    listingFor: 1,
    category: "rumah",
    state: "",
    district: "",
    area: "",
  }
) => {
  analytic.track("listing-view", {
    listingId: listingId,
    from: config.from,
    price: config.price,
    listingFor: config.listingFor,
    category: config.category,
    state: config.state,
    district: config.district,
    area: config.area,
  });
};

export const TRACK_RECOMMENDATION_LISTING_CLICK = (
  listingId,
  config = {
    from: "home",
  }
) => {
  analytic.track("collaborative-listing-detail", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_SEARCH_SUMBIT = (
  search_word = "",
  config = {
    from: "home",
  }
) => {
  analytic.track("search", {
    search_word: search_word,
    from: config.from,
  });
};

export const TRACK_LOGIN_SELECTED = () => {
  analytic.track("login-selected");
};

export const TRACK_REGISTER_SELECTED = () => {
  analytic.track("register-selected");
};

export const TRACK_LOGIN_SUCCESS = () => {
  analytic.track("login-success");
};

export const BANNER_NEW_YEAR_CLICK = () => {
  analytic.track("banner-promo-new-year-clicked");
};

export const TRACK_ADD_LISTING = (
  config = {
    from: "home",
  }
) => {
  analytic.track("add-listing-click", {
    from: config.from,
  });
};

export const TRACK_AUTOCOMPLETE_CLICK = (
  search_word = "",
  config = {
    from: "home",
  }
) => {
  analytic.track("autocomplete-click", {
    search_word: search_word,
    from: config.from,
  });
};

export const TRACK_LISTING_WISHLIST = (
  listingId = 0,
  config = {
    from: "listing-list",
  }
) => {
  analytic.track("listing-wishlist", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_SHARE = (
  listingId = 0,
  config = {
    from: "listing-list",
  }
) => {
  analytic.track("listing-share", {
    listingId: listingId,
    from: config.from,
  });
};
export const TRACK_LISTING_SHARE_SUCCESS = (
  listingId = 0,
  config = {
    from: "listing-list",
  }
) => {
  analytic.track("listing-share-success", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_SHARE_CANCEL = (
  listingId = 0,
  config = {
    from: "listing-list",
  }
) => {
  analytic.track("listing-share-cancel", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_CONTACT_FROM_CARD = (
  listingId = 0,
  config = { from: "listing-card", isLogin: false }
) => {
  analytic.track("listing-contact-from-card", {
    listingId: listingId,
    from: config.from,
    isLogin: config.isLogin,
  });
};

export const TRACK_LISTING_CONTACT_FROM_CARD_NEED_LOGIN = (
  listingId = 0,
  config = { from: "listing-card", isLogin: false }
) => {
  analytic.track("guest-listing-contact-from-card", {
    listingId: listingId,
    from: config.from,
    isLogin: config.isLogin,
  });
};

export const TRACK_LISTING_CANCEL_CONTACT_FROM_CARD_NEED_LOGIN = (
  listingId = 0,
  config = { from: "listing-card", isLogin: false }
) => {
  analytic.track("guest-listing-cancel-contact-from-card", {
    listingId: listingId,
    from: config.from,
    isLogin: config.isLogin,
  });
};

export const TRACK_LISTING_CONTACT = (
  listingId = 0,
  config = {
    from: "listing-detail",
    isLogin: false,
  }
) => {
  analytic.track("listing-contact", {
    listingId: listingId,
    from: config.from,
    isLogin: config.isLogin,
  });
};

export const TRACK_LISTING_CONTACT_NEED_LOGIN = (
  listingId = 0,
  config = {
    from: "listing-detail",
    isLogin: false,
  }
) => {
  analytic.track("guest-listing-contact", {
    listingId: listingId,
    from: config.from,
    isLogin: config.isLogin,
  });
};

export const TRACK_LISTING_CANCEL_CONTACT_NEED_LOGIN = (
  listingId = 0,
  config = {
    from: "listing-detail",
    isLogin: false,
  }
) => {
  analytic.track("guest-listing-cancel-contact", {
    listingId: listingId,
    from: config.from,
    isLogin: config.isLogin,
  });
};

export const TRACK_LISTING_CONTACT_WA_FROM_CARD = (
  listingId = 0,
  config = {
    from: "listing-card",
  }
) => {
  analytic.track("listing-contact-wa-from-card", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_CONTACT_WA = (
  listingId = 0,
  config = {
    from: "listing-detail",
  }
) => {
  analytic.track("listing-contact-wa", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_CONTACT_CALL_FROM_CARD = (
  listingId = 0,
  config = {
    from: "listing-card",
  }
) => {
  analytic.track("listing-contact-call-from-card", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_CANCEL_CONTACT_CALL_FROM_CARD = (
  listingId = 0,
  config = {
    from: "listing-card",
  }
) => {
  analytic.track("listing-cancel-contact-call-from-card", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_CONTACT_CALL = (
  listingId = 0,
  config = {
    from: "listing-detail",
  }
) => {
  analytic.track("listing-contact-call", {
    listingId: listingId,
    from: config.from,
  });
};

export const TRACK_LISTING_CANCEL_CONTACT_CALL = (
  listingId = 0,
  config = {
    from: "listing-detail",
  }
) => {
  analytic.track("listing-cancel-contact-call", {
    listingId: listingId,
    from: config.from,
  });
};

export const REEL_CLICK = (
  value = "",
  config = {
    from: "home",
  }
) => {
  analytic.track("reel-click", {
    from: config.from,
    value: value,
  });
};

export const LISTING_CAROUSEL_ZOOM_CLICK = (listingId) => {
  analytic.track("listing-carousel-zoom-click", {
    listingId: listingId,
  });
};

export const TRACK_LISTING_VIEW_DETAIL = (listingId) => {
  analytic.track("listing-view-detail", {
    listingId: listingId,
  });
};
