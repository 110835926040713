import gql from "graphql-tag"
import { initializeApollo } from "../apollo-client"

export const sendFeedback = async ({
  userId,
  type,
  id,
}) => {
  const client = initializeApollo();
  return await client.mutate({
    mutation: gql`
      mutation SendFeedback($input: UserFeedback!) {
        feedback {
          send_feedback(input: $input)
        }
      }
    `,
    variables: {
      input: {
        user_id: userId.toString(),
        feedback_type: type,
        listing_id: id,
      }
    }
  })
}